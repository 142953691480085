import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import { Card, Form, Button, Navbar, Container, Tabs, Tab } from 'react-bootstrap';

const MAX_CHARS = 10000; // 最大字符数

function App() {
  const [file, setFile] = useState(null);
  const [questions, setQuestions] = useState('');
  const [questionCount, setQuestionCount] = useState(3);  // 默认为3个问题
  const [language, setLanguage] = useState('English');  // 默认为英文
  const [isLoading, setIsLoading] = useState(false);
  const [textInput, setTextInput] = useState(''); // 文本输入的状态
  const [remainingChars, setRemainingChars] = useState(MAX_CHARS);
  const questionsRef = useRef(null);

  const handleTextInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= MAX_CHARS) {
      setTextInput(inputText);
      setRemainingChars(MAX_CHARS - inputText.length); // 更新剩余字符数
    }
  };

  const handleTextSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // 启动加载状态
    const formData = new FormData();
    formData.append('text_input', textInput);
    formData.append('question_count', questionCount);  // 添加问题数量
    formData.append('language', language);

    try {
      const response = await fetch('http://api.learning520.com:8001/text/', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      setQuestions(data.questions);
    } catch (error) {
      console.error('请求出错:', error);
    }
    setIsLoading(false); // 关闭加载状态
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleQuestionCountChange = (event) => {
    setQuestionCount(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // 启动加载状态
    const formData = new FormData();
    formData.append('file', file);
    formData.append('question_count', questionCount);  // 添加问题数量
    formData.append('language', language);

    try {
      const response = await fetch('http://api.learning520.com:8001/upload/', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      setQuestions(data.questions);
    } catch (error) {
      console.error('请求出错:', error);
    }
    setIsLoading(false); // 关闭加载状态
  };

  useEffect(() => {
    if (questions) {
      questionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [questions]);

  return (
    <div>
      <Navbar bg="dark" variant="dark" className="mb-4 text-center">
        <Navbar.Brand href="#home" style={{ margin: 'auto' }}>
          出题小助手
        </Navbar.Brand>
      </Navbar>

      <Container>
        <Tabs defaultActiveKey="upload" className="mb-3">
          <Tab eventKey="upload" title="上传 PDF">
            <Card className="mb-3">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>上传 PDF（最多 10 页）</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>问题数量</Form.Label>
                    <Form.Control type="number" value={questionCount} onChange={handleQuestionCountChange} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>语言</Form.Label>
                    <Form.Control as="select" value={language} onChange={handleLanguageChange}>
                      <option value="English">英文</option>
                      <option value="Chinese">中文</option>
                      <option value="French">法文</option>
                    </Form.Control>
                  </Form.Group>
                  <br />
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? '生成问题中，请稍后...' : '生成问题'}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Tab>
          <Tab eventKey="text" title="输入文本">
            <Card className="mb-3">
              <Card.Body>
                <Form onSubmit={handleTextSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>输入文本（最多 10000 个字符）</Form.Label>
                    <Form.Control as="textarea" rows={3} value={textInput} onChange={handleTextInputChange} />
                    <Form.Text className="text-muted">
                      还可以输入 {remainingChars} 个字符
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>问题数量</Form.Label>
                    <Form.Control type="number" value={questionCount} onChange={handleQuestionCountChange} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>语言</Form.Label>
                    <Form.Control as="select" value={language} onChange={handleLanguageChange}>
                      <option value="English">英文</option>
                      <option value="Chinese">中文</option>
                      <option value="French">法文</option>
                    </Form.Control>
                  </Form.Group>
                  <br />
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? '生成问题中，请稍后...' : '生成问题'}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>

        {questions && (
          <Card ref={questionsRef}>
            <Card.Body>
              <Card.Title>生成的问题</Card.Title>
              <Card.Text style={{ whiteSpace: 'pre-line' }}>
                {questions}
              </Card.Text>
            </Card.Body>
          </Card>
        )}
      </Container>
    </div>
  );
}

export default App;
